import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Driver } from './driver';
import { FileReaderService } from '../../app-commons/file-reader/file-reader.service';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { MocksService } from '../mocks/mocks.service';
import { SpreadsheetService } from '../../ranking/results/spreadsheet.service';
import { Race } from '../../ranking/races/race';

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  drivers: Driver[] = [];

  constructor(
    private fileReaderService: FileReaderService,
    private spreadsheetService: SpreadsheetService,
    private mocks: MocksService
  ) {
  }

  requestDrivers(race: Race, options?: any): Observable<Driver[]> {
    if (race.driversSpreadsheet) {
      return this.requestDriversSpreadsheet$(race.driversSpreadsheet, options);
    } else if (race.driversCsvFile) {
      return this.requestDriversCsv(race.driversCsvFile);
    } else {
      return EMPTY;
    }
  }

  requestDriversCsv(mockPath: string): Observable<Driver[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parseCsv(csvContent))
    );
  }

  requestDriversSpreadsheet$(fileId: string, options?: any): Observable<Driver[]> {
    return this.spreadsheetService.poll(fileId, options).pipe(
      // filter(json => json && json.feed && json.feed.entry),
      // map(json => this.parseSpreadsheetJson(json.feed.entry))
      map(csv => this.parseCsv(csv)),
    );
  }

  findByEpc(epc: string): Driver | undefined {
    return this.drivers.find(driver => driver.epc === epc);
  }

  findByStartNumber(startNumber: number): Driver | undefined {
    return this.drivers.find(driver => driver.startNumber === startNumber);
  }

  setFromCsv(csvContent) {
    this.drivers = this.parseCsv(csvContent);
  }

  private parseCsv(data: string): Driver[] {
    const json = this.fileReaderService.parseCsv(data, ',', null, null, false);
    const headers = json.shift();
    const headersMap: any = {};
    headers.forEach((value, key) => headersMap[value] = key);

    return json.map(row => ({
        startNumber: +row[headersMap.numer],
        name: row[headersMap['nick']] || row[headersMap['imię']],
        epc: row[headersMap.EPC].replace(/\s+/, ''),
        model: headersMap.model ? row[headersMap.model] : '',
        group: headersMap.grupa ? +row[headersMap.grupa] : null,
        category: +row[headersMap.kategoria],
        spec: +row[headersMap.spec],
        stock: +row[headersMap.spec] === 3,
        street: 0,
        women: row[headersMap['płeć']] === 'k',
        extreme: headersMap.extreme && +row[headersMap.extreme],
        yt: headersMap.yt && +row[10] === 1
      } as Driver));
  }

  private parseSpreadsheetJson(entry: any[]): Driver[] {
    return entry.map(row => ({
      startNumber: +row.gsx$numer.$t,
      name: row.gsx$nick.$t,
      epc: row.gsx$epc.$t.replace(/\s+/, ''),
      model: row.gsx$model && row.gsx$model.$t,
      group: row.gsx$grupa && +row.gsx$grupa.$t,
      category: +row.gsx$kategoria.$t,
      spec: +row.gsx$spec.$t,
      stock: +row.gsx$spec.$t === 3,
      street: 0,
      women: row['gsx$płeć'].$t === 'k',
      extreme: +row.gsx$extreme.$t,
      yt: row.gsx$yt && +row.gsx$yt.$t === 1
    } as Driver));
  }
}
